import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class WhatsAppService {
  
  async getWhatsAppAll() {
    const whatsapps = await fetchWrapper.get(`${ruta}/whatsapp`);
    return whatsapps;
  }
  async sendFrmNew(datos) {
    const nuevomedico = await fetchWrapper.post(`${ruta}/whatsapp`, datos);
    return nuevomedico;
  }


  async updateFrm(datos) {
    const medicoActualizado = await fetchWrapper.put(
      `${ruta}/whatsapp/` + datos.id,
      datos
    );
    return medicoActualizado;
  }

  async deleteFrm(id) {
    return await fetchWrapper.delete(`${ruta}/whatsapp/${id}`);
  }
}
